@use '@angular/material' as mat;

$palette-primary: mat.define-palette(mat.$light-blue-palette, 300, 200, 500);

$palette-accent: mat.define-palette(mat.$teal-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$palette-warn: mat.define-palette(mat.$red-palette, 300, 200, 500);

// Create the theme object (a Sass map containing all of the palettes).
$color-theme: mat.define-dark-theme($palette-primary, $palette-accent, $palette-warn);

$color-primary: mat.get-color-from-palette($palette-primary);
$color-primary-light: mat.get-color-from-palette($palette-primary, 'lighter');
$color-primary-dark: mat.get-color-from-palette($palette-primary, 'darker');

$color-accent: mat.get-color-from-palette($palette-accent);
$color-accent-light: mat.get-color-from-palette($palette-accent, 'lighter');
$color-accent-dark: mat.get-color-from-palette($palette-accent, 'darker');

$color-white: white;
$color-text-primary: white;
$color-text-primary-light: #333333;
$color-text-primary-dark: #eeeeee;

$color-border-darkmode: rgba(255,255,255,0.5);

$color-text-secondary: white;
$color-text-secondary-light: #555555;
$color-subtitle: rgba(0, 0, 0, 0.54);
$color-text-secondary-dark: #eeeeee;

$color-background: #a0c5e7;
$color-facebook: #3B5998;

$color-danger: #ff566c;

$size-header-height: 64px;

$size-screen-without-header: calc(100vh - 64px);

$size-margin-card: 8px;

$size-avatar: 64px;
$size-avatar-border: 2px;

$size-form-min: 150px;
$size-form-max: 500px;

$size-leaderboard-title-s: 18px;
$size-leaderboard-text-s: 14px;
$size-leaderboard-subtext-s: 11px;
$size-leaderboard-line-spacing-s: 8px;
$size-leaderboard-side-widget-s: 300px;

$size-leaderboard-title-m: 24px;
$size-leaderboard-text-m: 18px;
$size-leaderboard-subtext-m: 16px;
$size-leaderboard-line-spacing-m: 12px;
$size-leaderboard-side-widget-m: 400px;

$size-leaderboard-title-l: 42px;
$size-leaderboard-text-l: 32px;
$size-leaderboard-subtext-l: 28px;
$size-leaderboard-line-spacing-l: 18px;
$size-leaderboard-side-widget-l: 540px;


$size-leaderboard-controls: 128px;
$size-leaderboard-social: 60px;
$size-leaderboard-header: 188px;
$size-leaderboard-header-w-padding: 198px;
$size-leaderboard-without-header-nor-padding: calc(100vh - 198px);

$size-player-avatar-s: 40px;
$size-player-avatar-m: 48px;
$size-player-avatar-l: 60px;
